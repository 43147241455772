.card {
    display: flex;
    flex-direction: column;
    height: 50.4rem;
    flex:1;
    background-color: transparent;
    margin: 0 1rem;
    transition: all .5s ease-in-out;
    position: relative;
    color: var(--color-white-1);

    &__active {
        transform: scale(1.03);
        flex: 2;
    }
    
    &__image {
        filter: brightness(50%);
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left;
        z-index: -1;
    }

    &__subtitle {
        font-weight: 400;
        font-size: 1.2rem;
        letter-spacing: .2rem;
        line-height: 1.4rem;
        text-transform: uppercase;
        padding: 3rem 3rem 0;
        opacity: 0.8;
    }

    &__title {
        font-size: 2.3rem;
        line-height: 3.3rem;
        font-weight: 500;
        padding: 2rem 3rem;
        max-width: 26rem;
        max-height: 11rem;
    }

    &__text {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 2.2rem;
        max-width: 24rem;
        padding: 0 3rem ;
        height: 26rem;
    }

    &__btn {
        margin-left: 3rem;
        width: 13.2rem;
        height: 4rem;
        background-color: var(--color-white-1);
        color: black;
        font-size: var(--font-size-small-2);
        line-height: 4rem;
    }

}