.container {
    display: flex;
    flex-direction: column;
    
    &:first-child {
        flex-direction: row;
    }

    .icon {
        margin-top: 3rem;
        max-height: 6rem;
        width: auto;
        margin-bottom: 1.9rem;
    }

    .title {
        color: #4A4A4A;
        font-family: Quicksand;
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 23px;
        text-align: center;
    }

    .form {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 auto;

        input {
            border: 1px solid #979797;
            border-radius: 21px;
            height: 4.5rem;
            padding-left: 12%;
            font-family: Quicksand;
            font-size: 1.5rem;

            background-image: url('../../../Assets/images/Lock.png');
            background-repeat: no-repeat;
            background-position: 5% 50%;

            &:nth-child(1) {
                margin-top: 4rem;
                margin-bottom: 2rem;
            }
        }
    }
    
    .forgot_password {
        color: #525F7F;
        font-family: Quicksand;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        text-decoration: underline;
        margin: 3rem auto 3rem auto
    }

    .button {
        height: 4rem;
        color: #FFFFFF;
        font-family: Quicksand;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 13px;
        text-align: center;
        background-color: #4A90E2;
        border-radius: 22px;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
        border: none;
    }

    .disabled {
        height: 4rem;
        color: #FFFFFF;
        font-family: Quicksand;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 13px;
        text-align: center;
        background-color: #999999;
        border-radius: 22px;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
        border: none;
        cursor: no-drop
    }
}