.StyledHam {
    width: 24px;
    height: 22.5px;
    //padding: 1rem;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    @media (min-width: 601px) {
        display: none;
    }

    & span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #000;
        border-radius: 1rem;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
            width: 100%;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 9px;
        }

        &:nth-child(4) {
            top: 1.8rem;
            width: 100%;
        }
    }

    &.active span {

        &:nth-child(1) {
            top: 9px;
            width: 0%;
            left: 50%;
        }

        &:nth-child(2) {
            transform: rotate(45deg);
        }

        &:nth-child(3) {
            transform: rotate(-45deg);
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 9px;
        }

        &:nth-child(4) {
            top: 9px;
            width: 0%;
            left: 50%;
        }

    }

}