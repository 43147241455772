.resumenContainer {
    padding-left:60px;
    padding-right:60px;

    .row {
        padding-top:30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 22px;

        .modalidad {
            min-width: 50%;
            .title {
                color: #6F6F6F;
                font-family: Quicksand;
                font-size: var(--font-size-small-2);
                font-weight: 700;
                line-height: 1.4rem;
                text-align: center;
            }

            .icon {
                margin-top: 1.1rem;
                margin-bottom: 2px;
                display: flex;
                justify-content: center;
                img {
                    width: 46px;
                    height: 31px;
                }
                p {
                    margin-left:6px;
                    color: #6F6F6F;
                    font-family: Quicksand;
                    font-size: 30px;
                    font-weight: 400;
                    line-height: 36px;
                    text-align: left;
                }
            }

            .nombrecompleto {
                color: #6F6F6F;
                font-family: Quicksand;
                font-size: var(--font-size-small-2);
                font-weight: 400;
                line-height: 1.4rem;
                text-align: center;
            }

        }

        .charet {
            display: flex;
            justify-content: center;
            transform: rotate(270deg);
        }
    }

    .carddivisor {
        border: 1px solid #D8D8D8;
        opacity: 0.4988374255952381;
    }
}

.Terms {
    margin-top:1.4rem;
    padding: 1.6rem 2.1rem 0 2.5rem;
    background-color: #FFFFFF;
    border: 1px solid rgba(216, 216, 216, 0.5);
    border-radius: 3px;
    box-shadow: 2px 3px 1rem 0 rgba(82, 82, 82, 0.15);
    height: 88px;

    p {
        color: #ACACAD;
        font-family: Quicksand;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.2rem;
        text-align: left;
    }

    .itemContainer {
        display: flex;
        margin-top:1.2rem;

        .item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:nth-child(2) {
                margin-left: 1.3rem;
            }

            .itemTitle {
                display: block;
                position: relative;
                padding-left: 1.5rem;
                cursor: pointer;
                color: #9B9B9B;
                font-family: Quicksand;
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 1.2rem;
                text-align: left;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                    &:checked {
                        ~ {
                            .checkmark {
                                &:after {
                                    display: block;
                                }
                            }
                        }
                    }
                }
                .checkmark {
                    &:after {
                        margin: 1px;
                        top: 0;
                        background-color: #3AAC7F;
                        border-radius: 100%;
                        width: 5px;
                        height: 5px;
                        left: 0;
                    }
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                border: 1px solid #3AAC7F;
                border-radius: 100%;
                width: 9px;
                height: 9px;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
        }
    }
}