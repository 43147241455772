.inputContainer {
    display: flex;
    justify-content: space-between;
    margin-top:0.8rem;
    .item {
        width: 45%;
        .itemTitle {
            color: #6F6F6F;
            font-family: Quicksand;
            font-size: var(--font-size-small-2);
            font-weight: 400;
            line-height: 1.4rem;
            text-align: left;
            margin-bottom: 2px;
            input {
                height: 25px;
                width: 100%;
                border: 0.75px solid #B5B5B5;
                color: #6F6F6F;
                font-family: Quicksand;
                font-size: var(--font-size-small-2);
                font-weight: 400;
                line-height: 1.4rem;
                text-align: left;
                padding-left: 1rem;
            }
        }
    }
}

.disclaimer {
    margin-top: 1.2rem;

    p {
        color: #9B9B9B;
        font-family: Quicksand;
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1rem;
        text-align: left;
    }
}