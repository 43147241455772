.card {
    display: flex;
    flex-direction: column;
    max-width: 36rem;
    min-height: 37.4rem;
    box-shadow: var(--box-shadow-strong-2);
    text-align: center;
    padding: 3.5rem;
    align-items: center;
    justify-content: flex-start;

    &__image {
        width: 11.7rem;
        height: 10.5rem;
    }

    &__header {
        color: var(--color-grey-dark-2);
        font-size: 1.8rem;
        font-weight: 500;
        text-transform: uppercase;
        margin: 2rem;
    }

    &__text {
        color: var(--color-grey-dark-3);
        font-size: 1.5rem;
        font-weight: 300;
        width: 76%;
        margin: 0 auto;

    }

}