.paginationBox {
    display: flex;
    align-items: center;
    justify-content: center;
    .pageNumberContainer {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        background-color: #FFFFFF;
        border: 1px solid #DEE2E6;
        border-radius: 100%;
        width: 36px;
        height: 36px;
    }
    .number {
        color: #8898AA;
        // font-family: .AppleSystemUIFont;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: -0.2px;
        line-height: 1.7rem;
        text-align: left;
    }
    .numberSelected {
        color: #FFFFFF;
    }
    .pageNumberContainerSelected {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
        background-color: #5E72E4;
        border-radius: 100%;
        box-shadow: var(--box-shadow-strong-1);
        width: 36px;
        height: 36px;
    }
}