.section {
    margin: 0 auto;
    width: 50.3rem;
    max-width: 100%;
    height: 70rem;
    background-color: white;

    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &__logo {
            width: 13.7rem;
            height: 6.3rem;
        }

        &__leyend {
            width: 26rem;
            text-align: center;
        }

        &__input {
            border: none;
            width: 41.8rem;
            max-width: 100%;
            height: 4.5rem;
            padding-left: 1rem;
            background-color: var(--color-white-2);
            font-size: var(--font-size-small-1);
            border-bottom: 1px solid #54DAA1;

            &_textarea {
                padding: 1rem;
                height: 18.3rem;
                resize: none;
            }
        }

        &__submit {
            width: 14.2rem;
            height: 4rem;
            border: none;
            background: var(--color-grey-light-3);
            transition: all .2s;

            &:hover {
                background: var(--color-grey-light-3)
            }
        }
    }
}