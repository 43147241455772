.residenciaContainer {
    img {
        margin-top: 5rem;
        margin-bottom: 2rem;
        width: 8rem;
        height: auto;
    }
    
    h2 {
        color: #4A4A4A;
        font-family: Quicksand;
        font-size: 3rem;
        font-weight: 700;
        line-height: 3rem;
        margin-bottom: 1rem;
    }
    
    p {
        color: #4A4A4A;
        font-family: Quicksand;
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.5rem;
        margin-bottom: 2.5rem;
    }
    
    input {
        width: 100%;
        border: 1px solid #979797;
        border-radius: 2.1rem;
        background-image: url('../../../Assets/images/Lock.png');
        background-repeat: no-repeat;
        background-position: 5% 50%;
        padding: 1rem;
        padding-left: 4rem;
        line-height: 2rem;
        color: #4A4A4A;
        font-family: Quicksand;
        font-size: 1.5rem;
        font-weight: 400;
        &::placeholder {
            color: #999999;
        }
    }
    
    .buttonContainer {
        margin-left: 5rem;
        margin-right: 5rem;
    
        button {
            margin-top: 2.6rem;
            background-color: #4A90E2;
            border-radius: 22px;
            height: 4rem;
            border: 0;
            color: #FFFFFF;
            font-family: Quicksand;
            font-size: 1.7rem;
            font-weight: 400;
            text-align: center;
            width: 100%;
        }
    }
}

