.section {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../Assets/images/background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    max-width: 100vw;
    height: 60rem;

    @media(max-width: 600px) {
        display: none;
    }
}

.home {
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    &__container {


        &__text {
            width: 48.7rem;
        }

        &__header {
            text-align: left;
            color: var(--color-white-1);
            margin: 10rem 0 4rem;

        }

        &__leyend {
            text-align: left;
            color: var(--color-white-1);
            width: 35rem;
        }
    }
}