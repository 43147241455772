.text {
    
    &__main_heading {
        color: var(--color-white-1);
        font-size: var(--font-size-huge-1);
        font-weight: 500;
        letter-spacing: 0.43px;
        line-height: 6rem;
    }

    &__secondary_heading_1 {
        font-size: var(--font-size-big-1);
        line-height: 2.6rem;
        font-weight: 700;
        color: var(--color-grey-dark-2);
        text-align: center;
    }

    &__secondary_heading_2 {
        font-size: 3.7rem;
        font-weight: 700;
        letter-spacing: 0.11px;
        color:var(--color-black-1);
    }

    &__secondary_heading_3 {
        font-size: var(--font-size-medium-1);
        font-weight: 700;
        color: var(--color-grey-dark-3);
    }

    &__subtitle {
        font-size: var(--font-size-medium-1);
        line-height: 2.5rem;
        font-weight: 400;
        color: var(--color-grey-light-2);
        text-align: center;
    }

    &__paragraph_1 {
        font-size: var(--font-size-small-2);
        font-weight: 400;
        letter-spacing: 1px;
        color: var(--color-grey-light-2);
    }

    &__paragraph_2 {
        font-size: var(--font-size-small-1);
        font-weight: 400;
        color: var(--color-grey-light-2);
    }

    &__tiny_paragraph_1 {
        font-size: 1rem;
        font-weight: 300;
        color: var(--color-grey-dark-1);
    }
}