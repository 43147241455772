.card {
    
   
    
    &__container {
        display: flex;
        border-radius: 1.6rem;
        transition: all, .15s;
        max-height: 30rem;
        overflow: hidden;

        &:nth-child(2){
            margin: 0 3rem;
        }

        &:hover {
            box-shadow: var(--box-shadow-strong-3);
        }

        &__small {
            flex:0.8;
        }

        &__big {
            flex:2;
        }
    }
}