.card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3.3px;
    height: 59px;

    &:hover {
        background: #D8D8D8;
    }

    &__flagbox {
        display: flex;
        flex-direction: column;
    }

    &__flag {
        width: 32.47px;
        height: 21px;
    }

    &__flagName {
        color: #454F5B;
        font-family: Quicksand;
        font-size: 9.2px;
        font-weight: 400;
        letter-spacing: 0.11px;
        line-height: 1.1rem;
        text-align: center;
    }


    &__buySellBox {
        padding-left: 1.7rem;
    }

    &__buySellPrice {
        color: #6F6F6F;
        font-family: Quicksand;
        font-size: 17.03px;
        font-weight: 400;
        line-height: 2rem;
        width: 63.37px;
        text-align: left;
    }

    &__buySell {
        color: #6F6F6F;
        font-family: Quicksand;
        font-size: 1.2rem;
        font-weight: 300;
        letter-spacing: 0.14px;
        line-height: 1.4rem;
        width: 55px;
        text-align: left;
    }
}

.card {
    background-color: #FFF;
}