.section {
    max-width: 100vw;
    margin: 0 auto;


    .card__container {
        width: 90%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 2rem;
        margin: 0 auto;
        align-content: center;
        grid-template-columns: repeat(auto-fill, minmax(378px, 1fr));

        @media(max-width: 600px) {
            grid-template-columns: repeat(auto-fill, minmax(336px, 1fr));
        }
    }

}