.card {
    position: relative;
    display: flex;
    box-shadow: var(--box-shadow-strong-1);
    min-height: 50px;
    padding: 1.5rem;
    align-items: center;
    transition: all .2s;
    margin-top:1.2rem;

    &__flagBox {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__flag {
        width: 32px;
        height: 2rem;
    }

    &__flagName {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.2rem;
        text-align: center;
    }

    .alerttext {
        height: 100%;
        color: #000000;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 1.6rem;
        font-weight: 400;
        text-align: left;
        padding: 0 50px 0 30px;
    }


    .alertclose {
        position: absolute;
        right: 1rem;
        img {
            width:10px;
            cursor: pointer;
        }
    }
}