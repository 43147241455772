.section {
    position: relative;

    .form {
        &__container {
            background-color: var(--color-white-1);
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 38rem;
            max-width: 100%;
            height: 24rem;
            padding: 1rem 1rem 1rem 6rem;
            justify-content: space-evenly;
            left: 60%;
            top: 10%;
            box-shadow: var(--box-shadow-strong-3);

            @media(max-width: 600px) {
                display: none;
            }
        }

        &__input {
            display: none;

            &_checked~label>span {
                opacity: 1;
                animation: moveInTop .4s ease-out;
            }
        }

        &__radio_button {
            height: 3.5rem;
            width: 3.5rem;
            display: inline-block;
            position: absolute;
            transform: translateY(-50%);
            background-image: url(../../../Assets/images/placeholder.svg);
            opacity: 0;
            left: 4%;

            &_1 {
                top: 25%;
            }

            &_2 {
                top: 66%;
            }
        }
    }

    .map {
        width: 100vw;
        max-width: 100%;
        height: 75vh;
        display: none;
        transition: all .2s;

        &__active {
            display: block;
        }
    }
}


@keyframes moveInTop {
    0% {
        opacity: 0;
        transform: translateY(-3rem);
    }

    100% {
        opacity: 1;

    }
}