.formularioContainer {
    padding-top: 1.2rem;

    .cardTitle {
        background-color: #FFFFFF;
        border: 0.5px solid rgba(216, 216, 216, 0.52);
        box-shadow: 2px 3px 4px 0 rgba(82, 82, 82, 0.16);
        padding: 0.8rem 1.8rem 21px 1.7rem;

        .title {
            color: #6F6F6F;
            font-family: Quicksand;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.7rem;
            text-align: left;
        }

        .titledivisor {
            margin-top: 6px;
            margin-bottom: 13.5px;
            border: 1px solid #D8D8D8;
        }
    }
}