.section {
    background-color: var(--color-white-1);
    max-width: 100vw;

    &__2 {
        position: sticky;
        top: 0;
        z-index: 100;
        box-shadow: var(--box-shadow-strong-1);
    }

    &__mobile1 {
        @media(max-width: 600px) {
            display: none;
        }
    }

    .nav {
        max-width: 84%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        font-size: var(--font-size-small-1);
        padding-top: 2rem;

        &__2 {
            padding-top: 3rem;
        }

        &__container {
            display: flex;
            align-items: center;
            align-content: space-between;

            &:last-child {
                width: 40%;
                justify-content: space-between;
            }

            &__2 {
                text-transform: uppercase;
                padding-bottom: 3rem;

                &:last-child {
                    width: 60%;
                }

            }

            &__mobile {
                @media(max-width: 600px) {
                    display: none;
                }
            }
        }

        &__flag {
            max-height: 2.3rem;
            max-width: 4.3rem;
            margin-right: .5rem;
        }

        &__buy {
            color: var(--color-grey-dark-1);
            text-transform: uppercase;
            margin-right: .5rem;
        }

        &__sell {
            color: var(--color-grey-dark-1);
            text-transform: uppercase;
        }

        &__text {
            color: var(--color-grey-light-1);
            text-decoration: none;
            border: none;
            text-transform: uppercase;
            outline: none;
            cursor: pointer;
            font-size: var(--font-size-small-1);
            font-family: 'QuickSand', sans-serif;
            background-color: transparent;
        }

        &__button {
            background-color: var(--light-blue-1);
            border: none;
        }

        &__logo {
            max-width: 26rem;
        }

        &__profileLogged {
            margin: 0 20px;
            min-width: 50px;
            background-color: transparent;
            color: #4A90E2;
            padding: 10px 45px;
            border-radius: 20px;
            position: relative;
            font-family: 'Quicksand';
            font-weight: 700;
            cursor: pointer;
            outline: none;

            span:nth-child(1) {
                position: absolute;
                left: 16px;
                top: 50%;
                -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
                outline: none;
            }

            img {
                width: .875em;
            }
        }

        &__profileLogOut {
            position: absolute;
            right: 16px;
            top: 50%;
            -webkit-transform: translate(0, -65%);
            transform: translate(0, -65%);
            outline: none;

            img {
                width: .625em;
            }
        }

        &__submenuLogged {
            display: block;
            padding: 5px 20px;
            position: absolute;
            background: #F3F3F3;
            border-right: 2px solid #4A90E2;
            box-shadow: 0px 9px 5px -5px rgba(0, 0, 0, 0.14);
            right: 31px;
            top: 26px;
            width: 200px;
            height: auto;
            text-align: right;
            padding-bottom: 10px
        }
        &__logged {
            line-height: 30px;
            text-decoration: none;
            color: #4A90E2;

            &:hover {
               text-decoration: underline;
            }
        }
    }
    

    .Wrapper {
        @media(min-width: 600px) {
            display: none;
        }

        width: 100%;
        height: 100vh;

        visibility: hidden;

        ;
        background-color: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0;
        right: 0;
        z-index: 20;
        margin-top: 7.6rem;
        transform: translateX(100%);
        transition: all .2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        display: flex;


        &.active {
            width: 70%;
            visibility: visible;
            transform: translateY(0%);
        }

        .MobileLinks {
            padding: 40px;
            width: 100%;
            list-style: none;
            margin: 0;
            display: flex;
            flex-direction: column;

            button,
            a {
                color: #F6F4F3;
                font-family: 'Quicksand', sans-serif;
                font-size: 1.42rem;
                font-weight: bold;
                letter-spacing: 0.8px;
                line-height: 1.6rem;
                text-align: left;
                text-decoration: none;
                margin-top: 30px;

                &.active {
                    font-weight: 700;
                }
            }
        }
    }
}