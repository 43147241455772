.u {
    &__margin_top_tiny_1   {margin-top:.5rem!important;}
    &__margin_top_tiny_2   {margin-top:1rem!important;}
    &__margin_top_small_1  {margin-top:2rem!important;}
    &__margin_top_small_2  {margin-top:3rem!important;}
    &__margin_top_small_3  {margin-top:6rem!important;}
    &__margin_top_medium_1 {margin-top:8rem!important;}
    &__margin_center       {margin:0 auto;}

    &__text_align_left   {text-align: left!important;}
    &__text_align_right  {text-align: right!important;}
    &__text_align_center {text-align: center!important;}

    &__border_radius_1 {border-radius: 3px;}
    &__border_radius_2 {border-radius: 9px;}

    &__scale_up_1 {transition: all, .2s; &:hover {transform: scale(1.03)!important;}}

    &__after_line_1 {
        position: relative;
        
        &:after {
            position: absolute;
            bottom: -3rem;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            width: 10rem;
            height: 1px;
            background-color: var(--color-grey-light-3);
        }
    }

    &__after_line_2 {
        position: relative;
        
        &:after {
            position: absolute;
            content: '';
            width: 15rem;
            height:2px;
            background-color: var(--color-grey-light-3);
            bottom: -25%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}