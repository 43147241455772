.modal  {
    position: fixed;
    width: 48.4rem;
    height: 37rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 200;
    background-color: white;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 16px;
    animation: blowUpModal 0.7s;
}

@keyframes blowUpModal {
    0% {
      transform:translateX(-50%) scale(0);
    }
    100% {
      transform:translateX(-50%) scale(1);
    }
}

.close img {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 10px;
    cursor: pointer;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modaltitle {
    color: #000000;
    font-family: 'Quicksand';
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.modalsubtitle {
    color: #666666;
    font-family: 'Quicksand';
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.2rem;
    text-align: center;
    max-width: 42.5rem;
}


.modalimgContainer {
    display: flex;
    justify-content: center;
    margin-top:1rem;
}

.modalbuttonContainer {
    display: flex;
    justify-content: center;
    margin-top:2.1rem;
    cursor: pointer;
}

.modalcontinuar {
    background: transparent;
    min-width: 23.1rem;
    height: 3.5rem;
    display: flex;
    font-family: Quicksand;
    font-weight: 400;
    font-size: 1.6rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-decoration: none;
    border-radius: 22px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
    color: #FFFFFF;
    font-family: 'Quicksand';
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.6rem;
    text-align: center;
    margin: 30px;
}

.modalcontinuar.warning {
    background-color: #EA4949;
}

.modalcontinuar.success {
    background-color: #3AAC7F;
}