.container {
    width: 100%;
    max-width: 990px;
    padding: 8rem 1rem 0;
    margin: 0 auto;

    @media (max-width: 600px) {
        padding-top: 2rem;
    }

    h1 {
        font-size: 2em;
        margin: 0.67em 0;

    }

    h3 {
        font-size: 1.4em;

    }

    .textContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__frst,
        &__scnd {
            margin-top: 30px;
            width: 473px;
            color: rgba(0, 0, 0, 0.5);
            font-family: Quicksand;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 28px;
            max-width: 100%;
            text-align: justify;
        }

        &__frst {

            p:nth-child(1),
            p:nth-child(2) {
                margin-bottom: 30px;
            }

            p:nth-child(4) {
                font-weight: bold;
            }
        }


    }

    .slider {
        width: 985px;
        max-width: 100%;
        margin: auto;
        overflow: hidden;
        margin-top: 30px;
        margin-bottom: 60px;

        ul {
            padding: 0;
            display: flex;
            width: 300%;
            -webkit-animation: cambio 20s infinite;
            animation: cambio 20s infinite;
        }

        li {
            list-style: none;
        }

        img {
            width: 100%;
        }

        @keyframes cambio {
            0% {
                margin-left: 0;
            }

            20% {
                margin-left: 0;
            }

            25% {
                margin-left: -100%;
            }

            45% {
                margin-left: -100%;
            }

            50% {
                margin-left: -200%;
            }

            70% {
                margin-left: -200%;
            }
        }
    }
}