.container {
    width: 100%;
    padding: 8rem 243px 246px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 150px;

    @media (max-width: 600px) {
        padding: 20px;
        display: block;
    }
}


.userDataBox {
    position: relative;
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: var(--box-shadow-strong-1);
    min-width: 15%;

    .loginData {
        .imgbox {
            position: relative;
            height: 150px;
            margin: 15px auto 0 auto;
            border-radius: 50%;
            box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
            max-width: 150px;
            height: 150px;
            position: relative;
            transition: 0ms;

            .onlyImgStyles {
                position: relative;
                height: 150px;
                overflow: hidden;
                border-radius: 50%;
            }
        }

        .profileImg {
            position: absolute;
            top: 50%;
            right: 50%;
            -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
            z-index: 1;
            width: auto;
            max-width: 100%;
            height: auto;
            margin: 0;
            padding: 0;
            border: none;
            line-height: normal;
            vertical-align: middle;
        }

        .editoval {
            display: flex;
            align-items: center;
            position: absolute;
            background-color: #4A90E2;
            border-radius: 100%;
            width: 36px;
            height: 36px;
            right: 5px;
            bottom: 5px;
            cursor: pointer;
            z-index: 1000;

            div {
                margin: 0 auto;
                .editIcon {
                    width:13.22px;
                    height:13.22px;
                }
            }
        }

        h1 {
            color: #32325D;
            font-family: Quicksand;
            font-size: 2rem;
            font-weight: 700;
            letter-spacing: 0.15px;
            line-height: 24px;
            text-align: center;
            margin-top: 16.22px;
        }

        p {
            color: #32325D;
            font-family: Quicksand;
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 0.08px;
            line-height: 1.2rem;
            text-align: center;
        }
    }

    .otherData {
        margin-top: 42px;
        div {
            &:nth-child(6) {
                padding-top: 1.5rem;
                padding-bottom: 6rem;
            }
        }
        .otherDataBox {
            text-align: center;
            margin-top:22px;

            .title {
                color: #414141;
                font-family: Quicksand;
                font-size: 0.8rem;
                font-weight: 300;
                letter-spacing: 0.08px;
                line-height: 1rem;
            }

            .data {
                color: #414141;
                font-family: Quicksand;
                font-size: 1.6rem;
                font-weight: 400;
                letter-spacing: 0.12px;
                line-height: 1.9rem;
            }

            .button {
                background: var(--color-blue-light-1);
                color: #FFFFFF;
                font-family: 'Quicksand';
                font-size: 14px;
                font-weight: 700;
                line-height: 17px;
                text-align: center;
                padding: 10px 30px;
                border-radius: 25px;
                border: none;
                margin: 0 auto;
            }
        }
    }
}

.formBox {
    background-color: #FFFFFF;
    border-radius: 3px;
    box-shadow: var(--box-shadow-strong-1);
    min-height: 542px;
    margin-left: 21px;
    padding-bottom: 50px;
    min-width:1091px;

    @media (max-width: 600px) {
        margin-left: 0;
        margin-top: 20px;
    }

    .tabs {
        position: relative;
        background-color: #FAFAFA;
        border-radius: 3px 3px 0 0;
        display: flex;
        list-style-type:none;

        @media (max-width: 600px) {
            li:nth-child(2), li:nth-child(3) {
                display: none;
            }
        }

        li {
            background-color: #FAFAFA;
            border-radius: 5px 5px 0 0;
            min-width: 91px;
            height: 44px;
            cursor: pointer;
            p {
                padding: 1.2rem 2rem 1.1rem 2rem;
                color: #999999;
                font-family: 'Nunito Sans', sans-serif;
                font-size: 1.4rem;
                font-weight: 700;
                line-height: 21px;
                text-align: center;
            }
            .active {
                background: #FFFFFF;
                color: #022047;
            }

        }

        .saveStatus {
            position: absolute;
            right: 0;
            padding: 1.1rem 44px 1.4rem;
            p {
                color: #5391F1;
                font-family: Quicksand;
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 1.8rem;
                text-align: center;
            }
        }
    }
}