.footer {
    padding: 10rem 0 5rem;
    width: 100vw;
    background-color: var(--color-white-2);
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }

    &__container {
        display: flex;
        flex-direction: column;
        width: 25%;

        @media (max-width: 600px) {
            width: 100%;
            margin-bottom: 1.5rem
        }
    }

    &__logo {
        width: 15rem;
    }

    &__link {
        text-decoration: none;
    }
}