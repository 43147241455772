* {
  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  /* 1rem = 10px, 10px/16px = 62.5% */
  scroll-behavior: smooth;
}

body {
  font-family: 'Quicksand', Helvetica, Arial, sans-serif;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size-small-1: 1.3rem;
  --font-size-small-2: 1.5rem;
  --font-size-small-3: 1.2rem;
  --font-size-medium-1: 1.8rem;
  --font-size-big-1: 2.7rem;
  --font-size-huge-1: 6rem;

  --color-white-1:      #ffffff;
  --color-white-2:      #fafafa;
  --color-white-3:      #F8F9FB;
  --color-black-1:      #000000;
  --color-grey-dark-1:  #333333;
  --color-grey-dark-2:  #454F5B;
  --color-grey-dark-3:  #6A7C92;
  --color-grey-light-1: #8E9196;
  --color-grey-light-2: #9b9b9b;
  --color-grey-light-3: #C4CDD5;
  --color-blue-light-1: #4A90E2;
  --color-green-light-1:#2DCE89;
  --color-orange-1:     #FB6340;

  --box-shadow-strong-1: 0 8px 8px 0 rgba(224, 224, 224, 0.8);
  --box-shadow-strong-2: 0 8px 20px 0 rgba(224, 224, 224, 0.5);
  --box-shadow-strong-3: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}