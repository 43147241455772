.form {
    display: flex;
    flex-direction: column;
    
    &__container_sub {
        display: inherit;
        flex-direction: column;
        position: relative;
    }
    

    &__input {
        border-radius: 1.5rem;
        border: 1px solid var(--color-grey-light-2);
        box-shadow: var(--box-shadow-strong-2);
        padding: 1rem;

        &:hover {
            box-shadow: var(--box-shadow-strong-3);
        }

        &:focus {
            box-shadow: var(--box-shadow-strong-3);
            border: none;
        }

        &_invalid{

            &_text {
                color: var(--color-orange-1);
                position: absolute;
                top: 99%;
                left: 3%;
                margin-top: .5rem;
            }

            &_border {
                border-color: var(--color-orange-1);
            }
            
            &_icon{
                display: flex!important;
                top: 70%;
            }

            &_icon_without_label {
                top: 62%;
            }
        }

        &_valid {
            
            &_border {
                border-color: var(--color-green-light-1);
            }

            &_icon{
                display: flex!important;
                top: 70%;
            }

            &_icon_without_label {
                top: 62%;
            }
        }
    }

    &__label {
        font-size: 1.2rem;
        margin-left: .5rem;
    }

    &__icon {
        display: none;
        position: absolute;
        width: 2rem;
        right: 3%;
    }

    &__active {
        display: flex;
        
        &_button {
            display: inline-block;
            width: 19rem;
        }
    }

    &__image {

        &_input_container {
            width: 38.6rem;
            height: 16.2rem;
            border-radius: 2.5rem;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            box-shadow: var(--box-shadow-strong-1);
        }

        &_label {
            position: absolute;
            top:0;
            left: 0;
            width: 38.6rem;
            height: 16.2rem;
            border-radius: 2.5rem;
        }

        &_input {
            display: none;
        }

        &_logo {
            width: 7rem;
            height: 7rem;
            display: flex;
            margin: 0 auto;
           
        }
        
        &_text {
            display: flex;
            align-self: center;
            color: var(--color-blue-light-1);
            font-size: var(--font-size-small-1);
            font-weight: 500;
        }

        &_preview {
            display: none;
            position: absolute;
            left: 0;
            top:0;
            width: 100%;
            height: 100%;
            border-radius: 2.5rem;

            &_active {
                display: flex;
            }
        }
    }
}      

