.section {

    .text {
        &__container {
            margin: 0 auto;
            max-width: 77.5rem;
        }
    }

    .card {
        &__container {
            display: flex;
            max-width: 77%;
            justify-content: space-around;
            margin: 0 auto;

            @media(max-width: 600px) {
                display: none;
            }
        }
    }
}