.container {
    display: flex;
    flex-direction: column;
    
    &:first-child {
        flex-direction: row;
    }
    
    .button {
        height: 6rem;
        width: 50%;
        border: none;
        position: relative;
        font-family: Quicksand;
        font-size: 1.7rem;
        font-weight: 500;
        letter-spacing: 0.55px;
        line-height: 11px;
        text-align: center;
    
        &_green {
            background-color: var(--color-green-light-1);
            color: var(--color-white-1);
            
            &:after {
                position: absolute;
                content: '';
                background-color: var(--color-green-light-1);
                width: 2rem;
                height: 2rem;
                transform: rotate(45deg);
                left:44%;
                top: 75%;
                
            }
        }
    
        &_white {
            background-color: var(--color-white-1);
        }
    }
}