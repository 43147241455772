.cardContainer {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1.4rem 1.6rem 1.4rem;
    cursor: default;

    .dropdownContainer {
        position: relative;
        overflow-y: scroll;
        max-height: 396px;
        background: #FFF;
        z-index: 100;
        box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.15);

        .cardStyles {
            cursor:default;
        }
    }

    .dropdownHide {
        display: none;
    }

    /* width */
    .dropdownContainer::-webkit-scrollbar {
        width: 4px;
    }

      /* Track */
    .dropdownContainer::-webkit-scrollbar-track {
        background:transparent;
    }

      /* Handle */
      .dropdownContainer::-webkit-scrollbar-thumb {
        background-color: #D8D8D8;
      }

      /* Handle on hover */
      .dropdownContainer::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

    .compraText {
        color: #6F6F6F;
        font-family: Quicksand;
        font-size: var(--font-size-small-3);
        line-height: 1.4rem;
        text-align: left;
    }

    .compraNumber {
        width: 100%;
        margin-top: 0.5rem;
        background: transparent;
        color: #6F6F6F;
        font-family: Quicksand;
        font-size: 28.5px;
        line-height: 34px;
        text-align: left;
        border:0px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
    -moz-appearance:textfield;
    }

    .cardCotizaciones {
        background-color: #FFFFFF;
        border-radius: 3px;
        box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.15);
        width: 248px;
        height: 61px;
    }
}

.carddivisor {
    border: 1px solid #D8D8D8;
    opacity: 0.4988374255952381;
}
