.formBox {
    padding-left: 39px;
    padding-top: 49.86px;
}

.buttonSection {
    display: flex;
    justify-content: flex-end;
    margin-right:4.6rem;
}

.button {
    background: var(--color-blue-light-1);
    color: #FFFFFF;
    font-family: 'Quicksand';
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    padding: 10px 30px;
    border-radius: 25px;
    margin-top: 25px;
    border: none;
}

.inputSection {
    display: flex;
    flex-wrap: wrap;
    padding-right: 30px;
}

.inputWrapper {
    position:relative;
    width: 100%;
    max-width: 25%;
    margin-bottom:47.86px;
}

.inputSection.AlertSection .inputWrapper {
    max-width: 33%;
}

@media (max-width: 600px) {
    .inputWrapper {
        max-width: 100%;
    }
}

.labelBoxElement {
        color: #333333;
        font-family: Quicksand;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 21px;
        text-align: left;
        padding-left: 1.7rem;
}

input:focus, select:focus {
    outline: none;
}

.textInputContainer {
    position:relative;
    margin-right:2.6rem;
    display:flex;
    align-items: center;
}

.textInput {
    font-family: Quicksand;
    margin-top: 9px;
    background-color: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 25px;
    width: 100%;
    height: 38px;
    padding-left: 1.7rem;
}

.textInput::placeholder {
    color: #999999;
    font-family: Quicksand;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
}

.textDenied {
    border: 1px solid red;
}

.textSuccess {
    border: 1px solid #4CE2A7;
}

.validationSuccessIcon, .validationDeniedIcon {
    position: absolute;
    right:1.5rem;
    margin-top: 5px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 21px;
}

.validationSuccessIcon {
    color:#4CE2A7
}

.validationDeniedIcon {
    color:red;
}

select::-ms-expand {
    display: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

.fakeInput {
    color: #999999;
    font-family: Quicksand;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    display: flex;
    align-items: center
}

.formBox .inputWrapper .inputCheckBox {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 5px;
    width: 100%;
}

@media (max-width: 600px) {
    .formBox .inputWrapper .inputCheckBox {
        bottom: 0;
   }
}

.formBox .inputWrapper .inputCheckBox .containercheckbox {
    position: relative;
    padding-left: 2.5rem;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 21px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.formBox .inputWrapper .inputCheckBox .containercheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.formBox .inputWrapper .inputCheckBox .containercheckbox input:checked ~ .checkmark:after {
    display: block;
}

.formBox .inputWrapper .inputCheckBox .containercheckbox .checkmark:after {
    margin: 4px;
    background-color: #4ce2a7;
    border-radius: 100%;
    width: 1rem;
    height: 1rem;
}

.formBox .inputWrapper .inputCheckBox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #4ce2a7;
    border-radius: 100%;
    width: 22px;
    height: 22px;
}

.formBox .inputWrapper .inputCheckBox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.inputUpload {
    height: 100%;
    position: relative;
    width: 25%;
}

.inputUpload div {
    cursor: pointer;
}

@media (max-width: 600px) {
    .inputUpload {
        width: 100%;
   }
}

.inputUpload .frontId, .inputUpload .backId {
    width: 100%;
    height: auto;
}

.inputUpload .backId {
    position: absolute;
    left: 26px;
}

@media (max-width: 600px) {
    .inputUpload .backId {
        margin-left: 0;
        margin-top: 20px;
   }
}

.inputUpload img {
    width: 100%;
    height: auto;
}

.inputUpload .uploadModalBoxDisable {
    position: absolute;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: var(--box-shadow-strong-1);
    width: 208px;
    top: 91px;
    left: 151px;
    z-index: 100;
    display: none;
}

.inputUpload .uploadModalBox {
    position: absolute;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: var(--box-shadow-strong-1);
    width: 208px;
    top: 91px;
    left: 151px;
    z-index: 100;
    display: inline-block;
}

.inputUpload .uploadInputs {
    background-color: #fafafa;
    height: 50px;
    padding-left: 1.2rem;
    display: flex;
    align-items: center;
}

.inputUpload label {
    color: #666;
    font-family: Quicksand;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
}

.inputUpload .uploadInputs:hover {
    background-color: #4A90E2;
}

.inputUpload .uploadInputs:hover label {
    color: #fff;
}

.AlertbuttonContainer {
    margin: 15px 0;
    width: 25%;
}

.AlertbuttonContainer div {
    transition: 300ms;
    background: #5e72e4;
    color: #fff;
    font-family: 'Quicksand';
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    text-align: center;
    padding: 10px 30px;
    border-radius: 25px;
    border: none;
    margin: 0 auto;
    outline: none;
    margin-left: 6px;
    cursor: pointer;
}

.AlertbuttonContainer .disabledButton {
    cursor: not-allowed;
    background: #ccc;
}
