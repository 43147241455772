.section {
    display: flex;
    width: 100vw;
    max-width: 100%;
    background-image: linear-gradient(90deg, #FB6340 0%,#FBB140 100%);
    padding-bottom: 14rem;



    .profile {

        &__container {
            display: flex;
            flex-direction: column;
            margin: 0 auto;

            &_sub {
                display: flex;
                align-items: center;
                width: 55.5rem;
                padding:  3rem;
                
                
                &:not(:first-child) {
                    background-color: var(--color-white-1);
                }
            }

            &_text {
                padding-left: 3rem; 
            }
            
        }

        &__header {
            font-size: 2.5rem;
            font-weight: 700;

            &_1 {
                color: var(--color-white-1);
            }

            &_2 {
                color: var(--color-green-light-1);
            }

            &_3 {
                color: var(--color-orange-1);
            }
          
        }

        &__text {
            font-size: 1.6rem;
            line-height: 2.7rem;

        }

        &__button {
            
            border: none;
            background-color: var(--color-white-1);
            font-size: 1.6rem;

            &_2{
                color: var(--color-green-light-1);
                display: none;
            }

            &_3{
                color: var(--color-orange-1);
                display: none;
            }
        }

        &__image {
            width: 9.3rem;
            background-size: contain;
            flex-shrink: 0;
            align-self: flex-start;
            margin-top: 1.5rem;
        }

        &__background_image {
            display: flex;
            margin: 20rem 0;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        
        &__container {
            display: none;
            flex-direction: column;
            width: 90%;
        }

        &__active {
            display: flex !important;
        }
    }      
}

