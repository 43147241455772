.modal {
    width:50rem;
    height: 60rem;
    display: flex;
    flex-direction: column;
    z-index: 200;
    background-color: white;
    position: fixed;
    top:20%;
    left: 50%;
    transform: translateX(-50%);
    animation: blowUpModal 1s;
}

@keyframes blowUpModal {
    0% {
      transform:translateX(-50%) scale(0);
    }
    50% {
      transform:translateX(-50%) scale(0);
    }
    100% {
      transform:translateX(-50%) scale(1);
    }
}