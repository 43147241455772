.section {
    background-color: var(--color-white-2);


    .container {

        display: flex;
        justify-content: space-around;
        width: 90%;
        margin: 0 auto;

        @media(max-width: 600px) {
            flex-direction: column;
        }

        &__text {
            display: flex;
            flex-direction: column;
            max-width: 37.7rem;
        }

        &__image {
            max-width: 50vw;
            display: block;

            .image {
                width: 31.3rem;
                height: 56.6rem;
            }
        }

        &__buttons {
            display: flex;

            @media(max-width: 600px) {
                flex-direction: column;
                align-items: center;
            }

            & a:last-child {
                margin-left: 2rem;

                @media(max-width: 600px) {
                    margin-left: 0;
                }
            }

            .button {
                width: 21rem;
                height: 5.9rem;
            }
        }
    }
}