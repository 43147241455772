.section {
    max-width: 100vw;
    margin: 0 auto;

    .text__container {

        &:nth-child(3) {
            max-width: 60rem;
            margin: 0 auto;
        }

    }

    .steps__container {
        display: flex;
        justify-content: space-evenly;
        position: relative;

        .step {
            display: flex;
            margin-bottom: 1.5rem;

            &__image {
                width: 5rem;
                height: 5rem;
            }

            &__text {
                align-self: center;
                font-size: 1.6rem;
                font-weight: 400;
                margin-left: 1rem;
            }
        }
    }

    .cards__container {
        display: flex;
        justify-content: space-evenly;
        position: relative;

        @media(max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }

    }

}