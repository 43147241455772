.card {
    display: flex;
    box-shadow: var(--box-shadow-strong-1);
    width: 96%;
    max-width: 45.2rem;
    height: 9.2rem;
    padding: 1.5rem;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    transition: all .2s;

    &:hover {
        transform: scale(1.05);
    }


    &__flagbox {
        display: flex;
        flex-direction: column;
    }

    &__flag {
        max-height: 3.3rem;
    }

    &__flagName {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.2rem;
        text-align: center;
    }


    &__buySellBox {

        padding: 0 3rem;

        @media(max-width: 600px) {
            padding: 0 2rem;
        }
    }

    &__buySellPrice {
        font-size: 2.4rem;
        font-weight: 400;
    }

    &__buySell {
        text-transform: uppercase;
        text-align: left;
    }
}