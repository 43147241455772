.container {
    width: 100%;
    padding: 1.6rem 30rem 6.3rem 30rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 15rem;
    margin-top: 1.6rem;

    .sidebar {
        margin-right:1.6rem;

        .operacion {
            background-color: #FFFFFF;
            border-radius: 1rem;
            box-shadow: var(--box-shadow-strong-1);
            padding: 1.4rem 2.1rem 2.1rem 2.1rem;
        }

        .cotizacionesContainer {
            margin-top:1.2rem;
            background-color: #FFFFFF;
            border-radius: 1rem;
            box-shadow: var(--box-shadow-strong-1);
            padding: 1.4rem 2.1rem 2.1rem 2.1rem;

            h1 {
                color: #6F6F6F;
                font-family: Quicksand;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 1.9rem;
                text-align: center;
            }
            
            .titledivisor {
                margin-top:0.8rem;
                margin-bottom: 12.5px;
                border: 1px solid #D8D8D8;
            }
            
            .cotizaciones {
                position: relative;
                overflow-y: scroll;
                max-height: 396px;
            }
            
            /* width */
            ::-webkit-scrollbar {
                width: 4px;
              }
            
              /* Track */
              ::-webkit-scrollbar-track {
                background:transparent;
              }
            
              /* Handle */
              ::-webkit-scrollbar-thumb {
                background-color: #D8D8D8;
              }
            
              /* Handle on hover */
              ::-webkit-scrollbar-thumb:hover {
                background: #555;
              }            
        }
    }

    .compraventa {
        position: relative;
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: var(--box-shadow-strong-1);
        min-width: 70rem;
        padding: 0.8rem 0.8rem 0 0.8rem;
    }
}

