.optionsContainer {
    display: flex;
    justify-content: space-between;

    .item {
        .itemTitle {
            display: block;
            position: relative;
            padding-left: 1.5rem;
            cursor: pointer;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2.1rem;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                &:checked {
                    ~ {
                        .checkmark {
                            &:after {
                                display: block;
                            }
                        }
                    }
                }
            }
            .checkmark {
                &:after {
                    margin: 1px;
                    top: 0;
                    background-color: #3AAC7F;
                    border-radius: 100%;
                    width: 0.5rem;
                    height: 0.5rem;
                    left: 0;
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 0.5rem;
            left: 0;
            border: 1px solid #3AAC7F;
            border-radius: 100%;
            width: 0.9rem;
            height: 0.9rem;

            &:after {
                content: "";
                position: absolute;
                display: none;
            }
        }
    }

    .itemDescriptionPay {
        margin-top: 6px;
        padding: 1.4rem 1.2rem 1.8rem 1.1rem;
        border: 1px solid #9B9B9B;
        box-shadow: 0.2rem 0.3rem 0.4rem 0 rgba(141, 141, 141, 0.5);
        border-radius: 0.3rem;
        height: 18rem;

        .text {
            margin-top: 1.2rem;
            color: #6F6F6F;
            font-family: Quicksand;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.9rem;
            text-align: left;
        }
    }

    .itemDescriptionDelivery {
        margin-top: 6px;
        padding: 1.4rem 1.2rem 1.8rem 1.1rem;
        border: 1px solid #9B9B9B;
        box-shadow: 0.2rem 0.3rem 0.4rem 0 rgba(141, 141, 141, 0.5);
        border-radius: 0.3rem;

        .text {
            margin-top: 1.2rem;
            color: #6F6F6F;
            font-family: Quicksand;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.9rem;
            text-align: left;
        }
    }

    .itemDescriptionSelectedPay {
        background-color: #3AAC7F;
        margin-top: 0.6rem;
        padding: 1.4rem 1.2rem 1.8rem 1.1rem;
        border: 0.1rem solid #9B9B9B;
        box-shadow: 0.2rem 0.3rem 0.4rem 0 rgba(141, 141, 141, 0.5);
        border-radius: 0.3rem;
        height: 18rem;

        .text {
            margin-top: 1.2rem;
            color: #FFFFFF;
            font-family: Quicksand;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.9rem;
            text-align: left;
        }
    }

    .itemDescriptionSelectedDelivery {
        background-color: #3AAC7F;
        margin-top: 0.6rem;
        padding: 1.4rem 1.2rem 1.8rem 1.1rem;
        border: 0.1rem solid #9B9B9B;
        box-shadow: 0.2rem 0.3rem 0.4rem 0 rgba(141, 141, 141, 0.5);
        border-radius: 0.3rem;

        .text {
            margin-top: 1.2rem;
            color: #FFFFFF;
            font-family: Quicksand;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.9rem;
            text-align: left;
        }
    }
}

.disclaimer {
    margin-top: 1.2rem;

    p {
        color: #9B9B9B;
        font-family: Quicksand;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.5rem;
        text-align: left;
    }
}