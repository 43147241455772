.link {

    &__container {
        display: flex;
        justify-content: space-between;
        width: 13rem;
    }

    &__element > img{
        width: 2.5rem;
        background-size: cover;
    }
}