.progressBar {
    background-color: #D8D8D8;
    border-radius: 1.5px;
    height: 3px;


    div {
        &:nth-child(1) {
            background-color: #3AAC7F;
            border-radius: 1.5px;
            height: 3px;
        }
    }
}

.stepone {
    width: 33%;
}

.steptwo {
    width: 66%;
}

.stepfour {
    width: 100%;
}

.stepContainer {
    margin: 1.4rem 28px 0 28px;
    max-width: 820px;

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        margin-top:1.4rem;

        .atras {
            border: 0.75px solid #3CAE7F;
            background: transparent;
            width: 92px;
            height: 35px;
            color: #3BAD7E;
            font-family: Quicksand;
            font-size: 13.5px;
            font-weight: 400;
            line-height: 1.6rem;
            text-align: center;
        }

        .atrasDisabled {
            border: 0.75px solid #3CAE7F;
            background: transparent;
            width: 92px;
            height: 35px;
            color: #3BAD7E;
            font-family: Quicksand;
            font-size: 13.5px;
            font-weight: 400;
            line-height: 1.6rem;
            text-align: center;
            cursor: no-drop
        }

        .continuar {
            background-color: #3BAD7F;
            border: 0.75px solid #3CAE7F;
            width: 167px;
            height: 35px;
            margin-left: 1.1rem;
            color: #FFFFFF;
            font-family: Quicksand;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.9rem;
            text-align: center;
        }

        #disable {
            cursor: no-drop
        }

        .confirmarOperacion {
            background-color: #3BAD7F;
            border: 0.75px solid #3CAE7F;
            width: 167px;
            height: 35px;
            margin-left: 1.1rem;
            color: #FFFFFF;
            font-family: Quicksand;
            font-size: 13.5px;
            font-weight: 400;
            line-height: 1.6rem;
            text-align: center;
        }       
    }

    .moduloSeguro {
        position: absolute;
        bottom: 1rem;

        img {
            width: 230px;
            height: 41px;
        }
    }
}

.modaltitle {
    margin-top:36px;
    margin-bottom: 0.5rem;
    color: #6F6F6F;
    font-family: Quicksand;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.8rem;
    text-align: center;
}

.modalsubtitle {
    color: #ACACAD;
    font-family: Quicksand;
    font-size: 1.3rem;
    font-weight: 400;
    // letter-spacing: -1.2px;
    line-height: 1.2rem;
    text-align: center;
}


.modalimgContainer {
    display: flex;
    justify-content: center;
    margin-top:1rem;
    .modalimg {
        width:332px;
        height: 204px;
    }
}

.modalbuttonContainer {
    display: flex;
    justify-content: center;
    margin-top:21px;

    .modalcontinuar {
        border: 0.75px solid #3CAE7F;
        background: transparent;
        min-width: 231px;
        height: 35px;
        display: flex;
        color: #3BAD7E;
        font-family: Quicksand;
        font-weight: 400;
        font-size: 1.6rem;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 20px;
        text-decoration: none;
    }
}