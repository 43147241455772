
.AlertasTableBox {
    display: flex;
    .AlertasTableRow {
        padding-left:50px;
        padding-top:2rem;
        min-width: 65%;
        .createAlertContainer {
            display:flex;
            margin-top: 2rem;
            cursor: pointer;
            .createAlertButton {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #4A90E2;
                border-radius: 50%;
                width: 50px;
                height: 50px;

                .plus {
                    font-size: 25px;
                    color:#FFF;
                    &::before {
                        content: '\FF0B';
                    }
                }

                .minus {
                    font-size: 25px;
                    color:#FFF;
                    padding-bottom: 4px;
                    &::before {
                        content: '\2212';
                    }
                }
            }
            .createAlertText {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left:1rem;
                color: #2D4EF5;
                font-family: Quicksand;
                font-size: 1.6rem;
                font-weight: 400;
                line-height: 1.9rem;
                text-align: left;
            }
        }
    }
    .iconAlerta{
        margin-left: 5%;
        margin-top: 10%;
        margin-right: 5%;
        .imgContent {
            text-align: center;

            img {
                height: 113px;
            }
        }
    }

    .textContainer{
        text-align: center;
        margin-top: 2rem;
        h1 {
            color: #4A4A4A;
            font-family: Quicksand;
            font-size: 1.9rem;
            font-weight: 700;
            line-height: 23px;
        }
        p {
            color: #4A4A4A;
            font-family: Quicksand;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.7rem;
        }
    }
}