.Modal  {
    position: fixed;
    top:0;
    left:0;
    background-color: rgba(0,0,0,.5);
    z-index:100;
    width: 100%;
    height: 100vh;
}

.container {
    display: flex;
    justify-content: flex-start;
}