.section {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-white-2);

    .leyend {
        margin: 0 auto;
        text-align: center;
        width: 55rem;
        max-width: 100%;
    }

}