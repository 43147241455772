.section {
    max-width: 100vw;
    margin: 0 auto;

    @media(max-width: 600px) {
        display: none;
    }

    .container {


        &__cards {
            display: flex;
            width: 85%;
            margin: 0 auto;
        }

    }



}