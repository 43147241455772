.btn {

    &,
    &:link,
    &:visited {
        border-radius: 10rem;
        text-transform: uppercase;
    }
   
    
    &__blue {
        background-color: var(--color-blue-light-1);
        border: none;
        color: white;
        font-size: var(--font-size-small-1);
        cursor: pointer;
        transition: all .2s;
        font-weight: 700;
        border-radius: 2.5rem;
        display: inline-block;
        padding: 1rem 3rem;
        
    }


    &__small {
        padding: 1rem 4.5rem;
    }
    
}