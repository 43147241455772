h1 {
    color: #6F6F6F;
    font-family: Quicksand;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    text-align: center;
}

.titledivisor {
    margin-top:0.8rem;
    margin-bottom: 21px;
    border: 1px solid #D8D8D8;
}

.operacionBox {
    padding-left: 6px;
    padding-right:6px;

    .compraVenta {
        display: flex;
        justify-content: space-between;

        .compraVentaText {
            color: #6F6F6F;
            font-family: Quicksand;
            font-size: var(--font-size-small-3);
            font-weight: 400;
            line-height: 1.4rem;
            text-align: left;
        }

        .compraVentaNumber {
            color: #6F6F6F;
            font-family: Quicksand;
            font-size: 18.4px;
            font-weight: 400;
            line-height: 22px;
            text-align: left;
        }

        img {
            width: 29px;
            height: 1.9rem;
        }

        .compraVentaCoin {
            color: #6F6F6F;
            font-family: Quicksand;
            font-size: 9.2px;
            font-weight: 400;
            letter-spacing: 0.1.1rem;
            line-height: 1.1rem;
            width: 24.1.5rem;
            text-align: center;
        }
    }

    .cambiodivisor {
        border: 1px solid #D8D8D8;
        opacity: 0.4999069940476191;
        margin: 0 auto;
        margin-top:8.5px;
        margin-bottom:11.5px;
        width: 50%;
    }
}